import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"
import arrowSvg from "../images/arrow-circle-right-solid.svg"

class ServiceR10Page extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="R10"
          description="View route information and buy your ticket for the R10 service operated by Tetley's Coaches."
        />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent">
            <div className="text-center">
              <h1 className="page-content-heading">R10 service</h1>
              <p>Rossett School, Harrogate - York Road, Leeds</p>
              <p>York Road, Leeds - Rossett School, Harrogate</p>
              <p style={{ margin: 0 }}>
                <a className='btn btn-grow btn-quote btn-ticket' href="https://passenger.shuttleid.uk/client/3108be57-5c93-4d16-84ff-0990d87eba95/35d3a14b-3d91-455a-a237-bcc7f6ab542a">
                  <span className='btn-quote__text'>Buy ticket</span>
                  <img className='btn-quote__icon' src={arrowSvg} alt="" />
                </a>
                <a style={{ display: 'inline-block', marginLeft: 15 }} href="https://passenger.shuttleid.uk">View My Tickets</a>
              </p>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <h2>Route</h2>
                <iframe
                  title="R10 Route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=18BNPIASsTw1JQyJXp2JnHvHxO-edASc&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>Towards</strong> Rossett School
                </p>
                <p>
                  From Aldi York Road, Oakwoood Roundabout, Scott Hall Road/Carr Manor Parade, Shadwell Lane, Shadwell Terminus, East Keswick, Wetherby
                </p>

                <p>
                  <strong>From</strong> Rossett School
                </p>
                <p>
                  To Wetherby, East Keswick, Shadwell Terminus, Shadwell Lane, Scott Hall Road/Carr Manor Parade, Oakwoood Roundabout, Aldi York Road
                </p>
              </div>
              <div className="page-content__text">
                <h2>Timetable</h2>
                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Morning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Aldi, York Road</td>
                      <td>DEP</td>
                      <td>0654</td>
                    </tr>
                    <tr>
                      <td>Oakwoood Roundabout</td>
                      <td>DEP</td>
                      <td>0705</td>
                    </tr>
                    <tr>
                      <td>Scott Hall Road/Carr Manor Parade</td>
                      <td>DEP</td>
                      <td>0710</td>
                    </tr>
                    <tr>
                      <td>Shadwell Lane</td>
                      <td>DEP</td>
                      <td>0720</td>
                    </tr>
                    <tr>
                      <td>Shadwell Terminus</td>
                      <td>DEP</td>
                      <td>0728</td>
                    </tr>
                    <tr>
                      <td>East Keswick</td>
                      <td>DEP</td>
                      <td>0738</td>
                    </tr>
                    <tr>
                      <td>Wetherby</td>
                      <td>DEP</td>
                      <td>0752</td>
                    </tr>
                    <tr>
                      <td>Rossett School</td>
                      <td>ARR</td>
                      <td>0835</td>
                    </tr>
                  </tbody>
                </table>

                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Afternoon</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Rossett School</td>
                      <td>DEP</td>
                      <td>1525</td>
                    </tr>
                    <tr>
                      <td>Wetherby</td>
                      <td>DEP</td>
                      <td>1555</td>
                    </tr>
                    <tr>
                      <td>East Keswick</td>
                      <td>DEP</td>
                      <td>1600</td>
                    </tr>
                    <tr>
                      <td>Shadwell Terminus</td>
                      <td>DEP</td>
                      <td>1605</td>
                    </tr>
                    <tr>
                      <td>Shadwell Lane</td>
                      <td>DEP</td>
                      <td>1610</td>
                    </tr>
                    <tr>
                      <td>Scott Hall Road/Carr Manor Parade</td>
                      <td>DEP</td>
                      <td>1620</td>
                    </tr>
                    <tr>
                      <td>Oakwood Roundabout</td>
                      <td>DEP</td>
                      <td>1630</td>
                    </tr>
                    <tr>
                      <td>Aldi, York Road</td>
                      <td>ARR</td>
                      <td>1640</td>
                    </tr>
                  </tbody>
                </table>

              </div>
            </div>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default ServiceR10Page
